<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="assignOrder-container">
        <div class="common-Summary-container no-after">
            <div class="Summary-item">
                <div class="item-title">今日接收订单总数</div>
                <div @click="() => { collect(
                        'todayReceptionNum',
                        '今日接收订单总数',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        ]
                    )}" class="item-num">{{ headerData.todayReceptionNum || "0" }}</div>
            </div>
            <div class="Summary-item">
                <div class="item-title">昨日接收定单总数</div>
                <div @click="() => { collect(
                        'yesdayReceptionNum',
                        '昨日接收定单总数',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        ]
                    )}" class="item-num">{{ headerData.yesdayReceptionNum || "0" }}</div>
            </div>
            <div class="Summary-item">
                <div class="item-title">本月接收定单总数</div>
                <div @click="() => { collect(
                        'monthReceptionNum',
                        '本月接收定单总数',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        ]
                    )}" class="item-num">{{ headerData.monthReceptionNum || "0" }}</div>
            </div>
            <div class="Summary-item">
                <div class="item-title">今日拒收订单总数</div>
                <div @click="() => { collect(
                        'todayRejectionNum',
                        '今日拒收订单总数',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        ]
                    )}" class="item-num">{{ headerData.todayRejectionNum || "0" }}</div>
            </div>
            <div class="Summary-item">
                <div class="item-title">今日接单金额</div>
                <div @click="() => { collect(
                        'todayReceptionPrice',
                        '今日接单金额',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        { name: '金额', colProp: ['price'] },
                        ]
                    )}" class="item-num">{{ headerData.todayReceptionPrice || "0" }}</div>
            </div>
            <div class="Summary-item">
                <div class="item-title">累计接单总金额</div>
                <div @click="() => { collect(
                        'totalReceptionPrice',
                        '累计接单总金额',
                        [
                        { name: '公司名称', type: 'input', modelKey: 'companyName' },
                        { name: '客户名称', type: 'input', modelKey: 'customerName' },
                        ],
                        [
                        { name: '订单编号', colProp: ['disOrderCode'] },
                        { name: '订单名称', colProp: ['disOrderName'] },
                        { name: '公司名称', colProp: ['companyName'] },
                        { name: '客户名称', colProp: ['customerName'] },
                        { name: '店铺名称', colProp: ['shopName'] },
                        { name: '金额', colProp: ['price'] },
                        ]
                    )}" class="item-num">{{ headerData.totalReceptionPrice || "0" }}</div>
            </div>
        </div>
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <div class="common-screen-container">
            <div class="common-input-container">
                <span>订单编号:</span>
                <el-input class="common-screen-input" v-model="params.disOrderCode" placeholder="订单编号"></el-input>
            </div>
            <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
                <span>公司名称:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.companyId" placeholder="请选择" filterable >
                    <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>客户名称:</span>
                <el-input class="common-screen-input" v-model="params.customerName" placeholder="客户名称"></el-input>
            </div>
            <div class="common-input-container">
                <span>订单名称:</span>
                <el-input class="common-screen-input" v-model="params.disOrderName" placeholder="订单名称"></el-input>
            </div>
            <div class="common-input-container">
                <span>派单状态:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.failFlag" placeholder="请选择" filterable >
                    <!-- <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
                    <el-option label="待接收" value="-1"></el-option>
                    <el-option label="接收成功" value="0"></el-option>
                    <el-option label="拒绝" value="1"></el-option>
                    <el-option label="作废" value="2"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>业务类型:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.type" placeholder="请选择" filterable >
                    <!-- <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
                    <el-option label="DSR修改" value="103"></el-option>
                    <el-option label="客服外包" value="104"></el-option>
                    <el-option label="电话销售客服" value="111"></el-option>
                    <el-option label="中差评修改" value="144"></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>店铺名称:</span>
                <el-input class="common-screen-input" v-model="params.shopName" placeholder="店铺名称"></el-input>
            </div>
            <div class="common-input-container">
                <span>所属平台:</span>
                <el-select class="common-screen-input" v-model="params.platformId" placeholder="所属平台" @change="commonFun" clearable filterable >
                <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>所属类目:</span>
                <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                <div class="common-select-page">
                    <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                    <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                    <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                    <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                </div>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>创建人:</span>
                <el-input class="common-screen-input" v-model="params.createName" placeholder="创建人"></el-input>
            </div>
            <div class="common-input-container" style="margin-bottom: 0;">
                <div class="common-input-container">
                    <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
                </div>
                <div class="common-input-container">
                    <el-button class="common-screen-btn" type="primary" @click="() => { assignOrder() }">查 询</el-button>
                    <el-button class="common-screen-btn clear" @click="() => {resetBtn()}" plain>重 置</el-button>
                </div>
            </div>
        </div>
        <!-- <el-button class="common-screen-btn" type="primary" @click="() => { $router.push('/assign-order/assign-list/assign-one') }">开始派单</el-button> -->
        <el-table :span-method="objectSpanMethod" class="common-table" :data="tableData">
            <el-table-column prop="shopName" label="订单编号" width="140px" fixed="left">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="() => { detailShow(scope.row) }">{{scope.row.disOrderCode || '--'}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="companyName" label="公司名称" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="disOrderName" label="订单名称" width="120px" :formatter="tableColumn" ></el-table-column>
            <!-- <el-table-column prop="failFlagName" label="派单状态" width="120px" :formatter="tableColumn" ></el-table-column> -->
            <el-table-column prop="failFlagName" label="派单状态" width="120px" >
                <template slot-scope="scope">
                    <div v-if="scope.row.failFlag == '-1'" style="color: #f09009">待接收</div>
                    <div v-if="scope.row.failFlag == '0'" style="color: #66cc1f">接收成功</div>
                    <div v-if="scope.row.failFlag == '1'" style="color: #f00">拒绝接收</div>
                    <div v-if="scope.row.failFlag == '2'" style="color: #ababab">作废</div>
                </template>
            </el-table-column>
            <el-table-column prop="typeName" label="业务类型" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="customerName" label="客户名称" width="120px" :formatter="tableColumn" ></el-table-column>
           
            <el-table-column prop="shopName" label="店铺名称" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="platformName" label="所属平台" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="categoryName" label="所属类目" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column label="服务时间" width="120px" >
                <template slot-scope="scope">
                    <div style="white-space:pre-line">{{`${scope.row.startTime}\n${scope.row.endTime}`}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="shopServiceDay" label="店铺服务天数" width="140px">
                <template slot-scope="scope">
                    <div class="tableDay-blue">{{scope.row.shopServiceDay}}</div>
                </template>
            </el-table-column>
            <el-table-column label="派单金额" width="120px"  >
                 <template slot-scope="scope">
                    <div style="color: #ec9429">{{scope.row.price}}</div>
                </template>
            </el-table-column>
            <el-table-column label="协议客服工作时间" width="160px" :formatter="tableColumn" >
                <template slot-scope="scope">
                    <div style="white-space:pre">{{scope.row.agreeStartTime}}~{{scope.row.agreeEndTime}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="serviceWorkTime" label="客服工作时长" width="140px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="agreeSubNum" label="协议子账号数量" width="140px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="createName" label="创建人" width="140px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="noticeName" label="通知人" width="120px" :formatter="tableColumn" ></el-table-column>
            <el-table-column prop="remark" label="备注" width="140px" :formatter="tableColumn" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
                <template slot-scope="scope">
                    <div v-if="scope.row.failFlag == '-1'">
                        <el-button type="text" size="small" @click="() => { receiveShow('receive',scope.row) }">接收</el-button>
                        <el-button type="text" size="small" @click="() => { receiveShow('rejection',scope.row) }" >拒收</el-button>
                    </div>
                    <div v-else>
                        <el-button type="text" size="small" style="color: #ababab">接收</el-button>
                        <el-button type="text" size="small" style="color: #ababab">拒收</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: center"
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e)"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            :current-page="params.pageNum"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
        <receive-list-detail ref="assignOrderDetail" :drawer="drawer" :handleClose="() => { handleClose() }"></receive-list-detail>
        <el-dialog v-loading="loading" element-loading-text="数据较多，拼命加载中..."  v-dialogDrag title="接收订单" class="revokeDialog" :append-to-body="true" width="400px" :visible.sync="receiveVisible">
          <div>确认接收该条订单吗？</div>
          <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="receiveVisible = false">取 消</el-button>
            <el-button class="common-screen-btn" type="primary" @click="() => {receiveOrder('receive')}">确 定</el-button>
          </span>
      </el-dialog>
      <el-dialog v-loading="loading" element-loading-text="数据较多，拼命加载中..."  v-dialogDrag title="拒收订单" class="revokeDialog" :append-to-body="true" width="530px" :visible.sync="rejectionVisible">
        <el-form :model="receiveParams" :rules="receiveRules" ref="receiveForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="原因类型：" prop="rejectionType">
                <el-select class="common-screen-input_100" v-model="receiveParams.rejectionType" placeholder="原因类型" filterable >
                    <el-option label="价格不合理" value="1"></el-option>
                    <el-option label="人力不足" value="2"></el-option>
                    <el-option label="其他" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="说明：" prop="rejectionRemark">
                <el-input class="common-form-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="receiveParams.rejectionRemark"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="common-screen-btn" @click="rejectionVisible = false">取 消</el-button>
          <el-button class="common-screen-btn" type="primary" @click="() => {receiveOrder('rejection')}">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 头部报表弹 -->
        <common-sum-dialog ref="commonSumDialog" :methodFuc="orderSumList" :commonSumVisible="commonSumVisible" @handleClose="sumVisibleClose"></common-sum-dialog>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import CommonDate from "../../components/common/commonDate.vue"
import { selectCategory, selectPlatform, getCustList } from "../../service/common.js"
import { assignOrder, receiveOrder, orderSumList } from "../../service/assignOrder.js"
import { Config, DateTransform, tableColumn } from "../../utils/index.js"
import ReceiveListDetail from "./children/receiveListDetail.vue"
import CommonSumDialog from "../../components/common/commonSumDialog.vue"

export default {
    components: {
        Breadcrumb,
        CommonDate,
        ReceiveListDetail,
        CommonSumDialog
    },
    data() {
        return {
            tableColumn,
            loading: false,
            drawer: false,
            receiveVisible: false,
            rejectionVisible: false,
            disOrderNo: '',
            disOrderCode: '',
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "销售", isLink: false },
                { title: "派单中心", isLink: false }
            ],
            params: {
                pageNum: 1,
                pageSize: 10,
                companyId: 0,
                startDate: DateTransform(new Date),
                endDate: DateTransform(new Date),
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            tableData: [],
            headerData: {},
            rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
            finishedList: [], // 所属客户下拉
            platList: [], // 所属平台
            categoryList: [], //类目下拉
            categoryParams: {
                pageNum: 1,
                pageSize: 10,
                name: ''
            },
            receiveParams: {},
            receiveRules: {
                rejectionType: [{ required: true, message: "请选择原因类型", trigger: "change" }],
                rejectionRemark: [{ required: true, message: "请填写拒收说明", trigger: "change" }]
            },
            commonRadio: 1,
            commonStartDate: DateTransform(new Date),
            commonEndDate: DateTransform(new Date),
             // 汇总弹窗所需数据
            commonSumVisible: false,
            orderSumList, // 列表接口方法
        }
    },
    created () {
        if (this.rolesCompany) {
            this.params.companyId = this.rolesCompany[0].id;
        }
        this.selectPlatform();
        this.selectCategory();
        this.getCustList();
        this.assignOrder()
    },
    methods: { // 数据汇总弹窗-start
        sumVisibleClose () {
            this.commonSumVisible = false
        },
        collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
            this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId);
            this.commonSumVisible = true;
        },
        // 数据汇总弹窗 -end
        receiveShow(type, row) { // 接收/拒收弹窗
          let receiveParams = {...this.receiveParams}
          receiveParams.disOrderNo = row.disOrderNo
          receiveParams.disOrderCode = row.disOrderCode
          this.receiveParams = receiveParams
          if ( type == 'receive' ) {
            this.receiveVisible = true
          } else {
            this.rejectionVisible = true
          }
        },
        async receiveOrder(type) { // 接收/拒收订单
          let receiveParams = {...this.receiveParams}
          if ( type == 'receive' ) {
            receiveParams.failFlag = 0
            if (receiveParams.rejectionType) {  delete receiveParams.rejectionType }
            if (receiveParams.rejectionRemark) {  delete receiveParams.rejectionRemark }
          } else {
            receiveParams.failFlag = 1
          }
          this.loading = true
          let { code } = await receiveOrder(receiveParams)
          this.loading = false
          if ( code == 200 ) {
            this.assignOrder()
            if ( type == 'receive' ) { 
              this.$message.success("接受成功") 
              this.receiveVisible = false
            }
            if ( type == 'rejection' ) {
              this.$message.success("拒收成功")
              this.rejectionVisible = false
            }
          }
        },
        handleClose() { // 关闭弹窗
            this.assignOrder()
            this.drawer = false
        },
        detailShow(row) { // 详情弹窗
            this.$refs.assignOrderDetail.getTableParams(row)
            this.drawer = true
        },
        objectSpanMethod({ row, columnIndex, column }) {
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2 || columnIndex == 3 || columnIndex == 4 || column.label == '操作') {
                if (row.spanRow) {
                    return {
                    rowspan: row.spanRow,
                    colspan: 1
                    };
                } else {
                    return {
                    rowspan: 0,
                    colspan: 0
                    };
                }
            }
        },
        async assignOrder() { // 分页and详情
            let params = {...this.params}
            this.loading = true
            let { data } = await assignOrder(params)
            this.loading = false
            let newArr = []
            if(data.records && data.records.length) {
                data.records.map((item) => {
                    if (item.detailList && item.detailList.length) {
                        item.detailList.map((itemTwo, indexTwo) => {
                            let newItem = {...item, ...itemTwo}
                            delete newItem.detailList
                            if (indexTwo == 0) {
                                newItem.spanRow = (item.detailList.length)
                            }
                            newArr.push(newItem)
                        })
                    }
                })
            }
            this.tableData = newArr
            this.pagination.total = data.total
            this.headerData = {
                todayReceptionNum: data.todayReceptionNum,
                yesdayReceptionNum: data.yesdayReceptionNum,
                monthReceptionNum: data.monthReceptionNum,
                todayRejectionNum: data.todayRejectionNum,
                todayReceptionPrice: data.todayReceptionPrice,
                totalReceptionPrice: data.totalReceptionPrice,
            }
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.pageNum = e;
            }
            this.assignOrder()
        },
        commonGetDate (startDate, endDate) { // 时间筛选
            this.params.startDate = startDate
            this.params.endDate = endDate
            this.assignOrder()
        },
        remoteMethod(val) {
            this.getCustList(val);
        },
        async getCustList(name) { // 所属客户下拉
            let resData = (await getCustList({ customerName: name })).data;
            this.finishedList = resData;
        },
        commonFun() {
            this.params.pageNum = 1;
            this.params.pageSize = 10;
            this.assignOrder()
        },
        selectClear () { // 下拉清空时触发
            this.categoryParams.pageNum = 1
            this.categoryParams.name = ''
            this.selectCategory()
        },
        selectPage(type) { // 下拉分页
            if (type == 'up') {
                this.categoryParams.pageNum--
            } else {
                this.categoryParams.pageNum++
            }
            this.selectCategory()
        },
        remoteCategory(val) { // 所属类目下拉-筛选
            this.categoryParams.pageNum = 1
            this.categoryParams.name = val
            this.selectCategory()
        },
        async selectCategory() { // 类目下拉
            let categoryParams = {...this.categoryParams}
            let { data } = await selectCategory(categoryParams)
            this.categoryList = data.list
        },
        async selectPlatform() { // 平台下拉
            let { data } = await selectPlatform({pageNum: -1})
            this.platList = data
        },
        resetBtn() { // 重置
            this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
            this.params = {
                pageNum: 1,
                pageSize: 10,
                startDate: DateTransform(new Date),
                endDate: DateTransform(new Date),
            };
            if (this.rolesCompany) {
                this.params.companyId = this.rolesCompany[0].id;
            }
            this.assignOrder()
        },
    }
}
</script>
<style lang="less" scoped>
.assignOrder-container {
    text-align: left;
    .tableDay-blue {
        padding: 4px 10px;
        line-height: 1;
        color: #1890ff;
        border: 1px solid #1890ff;
        background: #f2f9ff;
        border-radius: 2px;
        display: inline-block;
    }
}
</style>